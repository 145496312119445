<template>
  <div>

    <div class="card-toolbar mb-5">
      <button class="btn btn-outline-primary font-weight-bolder ml-5" @click="openAdvancedSearch">
        <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
      </button>
    </div>

    <div v-if="showAdvancedSearch" class="card card-custom mb-5">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row">
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="inventories">{{ $t('inventories.inventories') }}</label>
              <!--                            <select name="" id="inventories" v-model="filters.inventory_id" class="custom-select">-->
              <!--                                <option v-for="row in inventories" :value="row.id" :key="row.id">-->
              <!--                                    {{ row.name }}-->
              <!--                                </option>-->
              <!--                            </select>-->

              <multiselect id="inventories"
                           v-model="inventory"
                           :multiple="false"
                           :options="inventories"
                           :placeholder="$t('inventories.inventories')"
                           :show-labels="false"
                           :show-no-options="false"
                           :show-no-results="false"
                           :taggable="true"
                           label="name"
                           track-by="id">
              </multiselect>
            </div>

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="product_id">{{ $t('inventories.products') }}</label>


              <div class="d-flex search-item-group">
                <select id="" v-model="f_by" class="custom-select" name="" style="width: 100px;">
                  <option selected value="name">{{ $t('items.name') }}</option>
                  <option value="sku_code">{{ $t('items.sku_code') }}</option>
                </select>
                <multiselect id="product_id"
                             v-model="product"
                             :internal-search="false"
                             :label="f_by?f_by:'sku_code'"
                             :multiple="false"
                             :options="items"
                             :placeholder="$t('purchases_invoices.item')"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :taggable="false"
                             track-by="id"
                             @search-change="getItems(f_by, $event)">
                </multiselect>
              </div>
            </div>
            <div class="form-group d-inline-flex col-md-6 mt-10">
              <button id="m_search" class="btn btn-primary my-auto mb-0 mr-2" type="button" @click="doFilter">
                <span><i class="fas fa-search"></i>
                  <span>{{ $t('search') }}</span>
                </span>
              </button>
              <button id="m_reset" class="btn btn-danger my-auto mb-0 mr-2" type="button" @click="resetFilter">
                <span><i class="fas fa-trash-restore"></i>
                  <span>{{ $t('reset_search') }}</span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::User-->
    <div class="card card-custom">
      <div class="card-body">
        <div class="d-flex justify-content-end mb-5">
          <!--          v-if="$can('inventory_statistics.export_data')"-->
          <custom-export-data :data-list="dataList"
                              :fields="json_fields" :file-name="fileName"></custom-export-data>
        </div>
        <v-server-table ref="table" :class="'dataTable table-row-dashed'" :columns="columns" :options="options">
          <template slot="actions" slot-scope="props">
            <v-btn color="pink" icon @click="showMoreAllItem(props.row.id)">
              <v-icon class="mr-2" small>mdi-eye</v-icon>
            </v-btn>
          </template>
        </v-server-table>
        <!--end: Datatable-->
      </div>
    </div>
    <!--end::User-->

    <!--    more details modal -->
    <b-modal ref="view-modal" :title="$t('more_details')" centered hide-footer size="lg">
      <div v-if="selectedItem" class="row">
        <div v-for="(item,idx) in  modalViewKeys" :key="'item'+idx" class="col-lg-6 col-md-6">
          <h6 class="pb-3 f-s-14">{{ $t(item) }}: <small>{{ selectedItem[item] === 0 ? 0 : selectedItem[item] || '-' }}</small></h6>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "index",
  data() {
    return {
      showAdvancedSearch: false,
      mainRouteDependency: 'base/dependency',
      filter: {
        sortBy: 'id',
      },
      filters: {
        inventory_id: null,
        product_id: null,
      },
      columns: ['id', 'inventory', 'product', 'user_name', 'type_status_name', 'invoice_code', 'actions'],
      validation: null,
      mainRoute: 'items/inventory_statistics_tracking',
      isEditing: false,
      id: null,
      inventories: [],
      items: [],
      dataList: [],
      selectedItem: null,
      modalViewKeys: [
        "inventory",
        "product",
        "open_stock_quantity_total_before",
        "open_stock_quantity_total_after",
        "available_stock_qty_before",
        "available_stock_qty_after",
        "stock_on_hand_qty_before",
        "stock_on_hand_qty_after",
        "sales_booking_before",
        "sales_booking_after",
        "purchase_on_way_before",
        "purchase_on_way_after",
        "committed_qty_before",
        "committed_qty_after",
        "booked_before",
        "booked_before_after",
        "booking_before",
        "booking_after",
        "type_type",
        "type_status_name",
        "type_id",
        "created_at"
      ],
      inventory: null,
      product: null,
      f_by: 'name',
    }
  },
  computed: {
    json_fields: function () {
      let fields = {};
      fields[this.$t('inventory_statistics.product')] = 'product';
      fields[this.$t('inventory_statistics.available_stock_qty')] = 'available_stock_qty';
      fields[this.$t('inventory_statistics.stock_on_hand_qty')] = 'stock_on_hand_qty';
      return fields;
    },
    fileName: function () {
      return this.$t('inventory_statistics.inventory_statistics');
    },
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          limit: that.$t('records'),
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          id: '#',
          inventory: this.$t('inventories.inventories'),
          product: this.$t('stocks_report.product_name'),
          user_name: this.$t('users.name'),
          type_status_name: this.$t('inventory_statistics.type_status'),
          invoice_code: this.$t('payment_sales_invoices.invoice_code'),
          actions: this.$t('more_details'),
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',
        }],
        orderBy: {'column': 'created_at'},

        alwaysShowPerPageSelect: true,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.limit,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(that.mainRoute, {..._params});

        },
        responseAdapter(resp) {
          that.dataList = resp.data.data.data;
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }
    },
  },
  watch: {
    inventory: function (val) {
      if (val) {
        this.filters.inventory_id = val.id;
      } else {
        this.filters.inventory_id = null;
      }
    },
    product: function (val) {
      if (val) {
        this.filters.product_id = val.id;
      } else {
        this.filters.product_id = null;
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: this.$t("MENU.Inventories"),
      route: '/settings/inventories'
    }, {title: this.$t("MENU.inventory_statistics_tracking")}]);

    this.getInventories();
    this.getItems();
  },
  methods: {
    showMoreAllItem(id) {
      this.dataList.forEach((item) => {
        if (item.id === id) {
          this.selectedItem = item;
        }
      });
      this.showModal();
    },
    showModal() {
      this.$refs['view-modal'].show()
    },
    hideModal() {
      this.$refs['view-modal'].hide();
    },
    getInventories() {
      ApiService.get(this.mainRouteDependency + `/inventories`).then((response) => {
        this.inventories = response.data.data;
      });
    },

    getItems(f_by = null, filter = null) {
      let _f_by = f_by ? f_by : 'name';
      if (filter && filter.length >= 3 && _f_by)
        ApiService.get(this.mainRouteDependency + "/items", {params: {[_f_by]: filter}}).then((response) => {
          this.items = response.data.data;
        });
    },


    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.inventory_id = null;
      this.filters.product_id = null;
      this.inventory = null;
      this.product = null;

      this.$refs.table.refresh();
    },

    getFetch() {
      this.$refs.table.refresh();
    },
  },
};
</script>
